<template>
	<div>
		<b-row>
			<b-col>
				<nav-component></nav-component>
			</b-col>
		</b-row>

		<create></create>		
		<list></list>		
		<deleted-orders></deleted-orders>		
	</div>
</template>
<script>
export default {
	components: {
		NavComponent: () => import('@/components/orders/components/Nav'),
		Create: () => import('@/components/orders/components/create/Index'),
		List: () => import('@/components/orders/components/list/Index'),
		DeletedOrders: () => import('@/components/orders/components/deleted-orders/Index'),
	},
}
</script>